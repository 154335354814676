import 'react-sliding-side-panel/lib/index.css'

import { Hidden } from '@mui/material'
import IonIcon from '@reacticons/ionicons'
import { Auth } from 'aws-amplify'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { Dispatch, useRef, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SlidingPanel from 'react-sliding-side-panel'
import { Slidingbar } from 'src/components/global/Slidingbar'
import { AccountMenu } from 'src/components/Header/AccountMenu'
import Logo from 'src/components/Header/Logo'
// import { TopicsMenu } from 'src/components/Header/TopicsMenu'
// import * as enVariables from 'src/config/config'
import { useGetTopicsForHeaderQuery } from 'src/graphql/generated/hooks'
import { removeAuth } from 'src/store/actions/auth'
import { AppState } from 'src/store/types'

import { EventsMenu } from './EventsMenu'
import styles from './Header.module.scss'
import { IndustryAnalysisMenu } from './IndustryAnalysisMenu'
import { InsightsMenu } from './InsightsMenu'
import { SolutionsMenu } from './SolutionsMenu'
import { TopicsMenu } from './TopicsMenu'

export interface MenuItem {
  title: string
  subMenuContained: boolean
}

export const Header: React.FC = () => {
  const authInfo = useSelector((state: AppState) => state.authInfo)
  const router = useRouter()
  const { data } = useGetTopicsForHeaderQuery({ fetchPolicy: 'cache-first' })
  const topicsData = data?.topics?.data || []
  const [topicCurrentIdx, setTopicCurrentIdx] = useState(0)
  const [isPaneOpen, setIsPaneOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  /* const onClosePanel = () => {
    setIsPaneOpen(false)
  } */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: Dispatch<any> = useDispatch()

  const [isSSOUser, setIsSSOUser] = useState(false)

  useEffect(() => {
    setIsSSOUser(localStorage.getItem('sso') === 'true')
  }, [])

  useEffect(() => {
    const id = setInterval(() => {
      setIsLoading(true)
      clearInterval(id)
    }, 1000)
  })

  const MENU_OPTIONS: MenuItem[] = [
    { title: 'LTH Solutions', subMenuContained: true },
    { title: 'Insights', subMenuContained: true },
    { title: 'Topics', subMenuContained: true },
    { title: 'Events', subMenuContained: true },
    { title: 'Industry Analysis', subMenuContained: true },
    /* { title: 'Legaltech Jobs', subMenuContained: false }, */
    { title: 'About Us', subMenuContained: false },
    { title: 'Sign In', subMenuContained: false },
    { title: 'Sign Up', subMenuContained: false },
  ]

  const MENU_OPTIONS_SIGNIN: MenuItem[] = [
    { title: 'LTH Solutions', subMenuContained: true },
    { title: 'Insights', subMenuContained: true },
    { title: 'Topics', subMenuContained: true },
    { title: 'Events', subMenuContained: true },
    { title: 'Industry Analysis', subMenuContained: true },
    /* { title: 'Legaltech Jobs', subMenuContained: false }, */
    { title: 'About Us', subMenuContained: false },
    { title: 'My Account', subMenuContained: true },
  ]

  const [activeMenu, setActiveMenu] = useState<string | null>(null)

  /* const handleSelectMenu = (title: string) => {
    if (title === 'Events') {
      setIsPaneOpen(false)
      router.push({ pathname: '/search', query: { type: 'upcoming' } })
    } else if (title === 'About Us') {
      setIsPaneOpen(false)
      router.push({ pathname: '/about' })
    }
  }
 */

  const menuRef = useRef<HTMLDivElement | null>(null) // Ref for detecting outside clicks

  // Handle clicking outside the open menu to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setActiveMenu(null) // Close the active menu when clicking outside
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleMenuToggle = (menuName: string) => {
    // If the same menu is clicked, close it; otherwise, switch to the new one
    setActiveMenu((prevMenu) => (prevMenu === menuName ? null : menuName))
  }

  const onClosePanel = () => {
    setIsPaneOpen(false)
    setActiveMenu(null) // Close all menus when the panel closes
  }

  const handleSelectMenu = (menuName: string) => {
    setIsPaneOpen(false)
    setActiveMenu(menuName)
  }

  const signOut = async () => {
    if (!isSSOUser) {
      await Auth.signOut()
    } else {
      removeAuth()
      localStorage.clear()
      router.push('/')
    }
    dispatch(removeAuth())
  }

  return (
    <div className={cn('w-full flex flex-row justify-center px-5 sm:px-0 bg-navy h-[3.1875rem]')}>
      {isLoading && (
        <div className="!max-w-[1100px] w-full h-full flex flex-row items-center justify-between">
          <Link className="mr-[40px]" href="/" passHref={true}>
            <Logo isPremium={authInfo.isPremium} isVendor={authInfo.isVendor} className="w-[12.5rem] h-[3.1875rem]" />
          </Link>

          <Hidden mdUp>
            <div className="flex flex-row ml-auto h-full">
              <IonIcon
                className="!h-[30px] !w-[30px] mt-[0.625rem] text-white !important"
                name="menu"
                onClick={() => {
                  setIsPaneOpen(true)
                }}
              />
              <div className="topHeaderMenu" style={{ backgroundColor: '#011d58' }}>
                <SlidingPanel type={'right'} isOpen={isPaneOpen} size={90}>
                  <Slidingbar
                    topics={topicsData}
                    options={authInfo.name === '' ? MENU_OPTIONS : MENU_OPTIONS_SIGNIN}
                    onSelectMenu={handleSelectMenu}
                    onClosePanel={onClosePanel}
                    onSignout={signOut}
                  />
                </SlidingPanel>
              </div>
            </div>
          </Hidden>
          <Hidden mdDown>
            <div ref={menuRef} className="topHeaderMenu flex flex-row items-center h-full gap-3">
              {/* Solution Menu */}
              <div onClick={() => handleMenuToggle('solutions')}>
                <SolutionsMenu isOpen={activeMenu === 'solutions'} />
              </div>

              {/* Insights Menu */}
              <div onClick={() => handleMenuToggle('insights')}>
                <InsightsMenu isOpen={activeMenu === 'insights'} />
              </div>

              {/* Topics Menu */}
              <div onClick={() => handleMenuToggle('topics')}>
                <TopicsMenu
                  topicsData={topicsData}
                  topicCurrentIdx={topicCurrentIdx}
                  handleSetTopicCurrentIdx={setTopicCurrentIdx}
                  isOpen={activeMenu === 'topics'}
                />
              </div>

              {/* Events Menu */}
              <div onClick={() => handleMenuToggle('events')}>
                <EventsMenu isOpen={activeMenu === 'events'} />
              </div>

              {/* IndustryAnalysis Menu */}
              <div onClick={() => handleMenuToggle('industryAnalysis')}>
                <IndustryAnalysisMenu isOpen={activeMenu === 'industryAnalysis'} />
              </div>

              {/* <SolutionsMenu />

              <InsightsMenu />

              <EventsMenu /> */}

              {/* <Link href="/events" className={cn(styles.menuItem, 'w-[70px]')}> Events </Link> */}

              {/* <IndustryAnalysisMenu /> */}

              {/* <Link
                href={enVariables.JOB_URL}
                passHref
                target="_blank"
                rel="noopener noreferrer"
                className={cn(styles.menuItem, 'w-[130px]')}
              >
                Legal<span className="text-turquoise">tech</span>&nbsp;Jobs
              </Link> */}

              <Link href="/about" className={cn(styles.menuItem)}>
                About Us
              </Link>

              <div style={{ borderLeft: '1px solid #fff', height: '30px', width: '1px' }}></div>

              {authInfo.name === '' || !authInfo ? (
                <div className="flex flex-row items-center relative gap-3">
                  <Link
                    href="/signin"
                    className={cn(styles.menuItem, 'relative inline-block h-full text-nowrap cursor-pointer text-lef')}
                  >
                    Sign In
                  </Link>
                  <Link
                    href="/pricing"
                    className="flex flex-row items-center h-[40px] text-white font-bold font-secondary text-[14px] bold text-center rounded-[22px] bg-navybright px-[12px]"
                  >
                    Sign Up
                  </Link>
                </div>
              ) : (
                <div className="flex flex-row items-center relative" onClick={() => handleMenuToggle('account')}>
                  <p className={cn(styles.accountName)}>{authInfo.name?.slice(0, 1).toUpperCase()}</p>
                  <AccountMenu
                    isOpen={activeMenu === 'account'}
                    handleSignout={signOut}
                    isPremium={authInfo.isPremium}
                    isVendor={authInfo.isVendor}
                  />
                </div>
              )}
            </div>
          </Hidden>
        </div>
      )}
    </div>
  )
}
